<template>
    <div  class="mt-7 mr-3">
        <associatedOption
                type="select"
                :fieldAttrInput="{style:'max-width:250px;','menu-props':{'maxHeight': '350'},'id':'userStatusFilter'}"
                :fieldAttributes='{ name: "Status Filter",associatedOption: result.userStatusList,"ignoreEmptyAdd":true,sort:true}'
                v-model="userStatusSelector"
        ></associatedOption>
    </div>
</template>

<script>
  import associatedOption from "@/commonComponents/associatedOption.vue";
  export default {
    props: ["currentTable","result"],
    data() {
      return {
        disabled: false,
        loading: false,
      };
    },
    components: {  associatedOption },
    created: function () {
      this.$root.$refs.explorerStatusFilter = this;
      this.getSelectedUserStatusData(1);
    },
    computed: {
      userStatusSelector: {
        get() {
          let value="1";// default is active users list!
          if (typeof this.currentTable.selectedStatus!='undefined') {
            value =  this.currentTable.selectedStatus;
          }
          return value;
        },
        set(newVal) {
          let currentTable = this.currentTable;
          this.$set(currentTable, "selectedStatus", newVal);
          this.$store.commit("tableChanged", currentTable);
          this.getSelectedUserStatusData(newVal);
        },
      },

      url() {
        let url = "";
        if (typeof this.currentTable["tableUrl"] != "undefined") {
          url = this.currentTable["tableUrl"];
        }
        return url;
      },

      id() {
        let id = "dataTable";
        if (typeof this.currentTable["id"] != "undefined") {
          id = this.currentTable["id"];
        }
        return id;
      },
    },
    methods: {
      getSelectedUserStatusData(status) {
        console.log(status);
        this.disabled = true;
        this.loading = true;
        var uri = this.url;
        var options = {
          function: "getUserStatusData",
          requestType: "ajax",
          statusType:status,
        };
        let caller = this;
        this.frameworkAxiosRequest({
          method: 'post',
          url: uri,
          data: options,
        })
            .then(function (response) {
            let currentTable = caller.currentTable;
            caller.$set(currentTable, "data", response.data.result.table.data);
            caller.$set(caller.result, "headerExtraInfo",  response.data.result.headerExtraInfo);
            //caller.updateTable(response.data.result.table,null);

            caller.$store.commit("tableChanged", currentTable);
            caller.disabled = false;
            caller.loading = false;
          })
          .catch(function (response) {
            console.log(response);
          });
      },
    }
  }
</script>